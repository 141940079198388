import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';

@Component({
  selector: 'if-demo-demo-wrapper',
  standalone: true,
  imports: [],
  templateUrl: './demo-wrapper.component.html',
  styles: ``
})
export class DemoWrapperComponent implements AfterViewInit {
  @ViewChild('code', {static: true}) codeRef!: ElementRef;
  @Input() code!: string;


  ngAfterViewInit() {
    this.codeRef.nativeElement.innerText = this.code;
  }
}
